<template>
    <div>
        <div class="columns">
            <div class="column">
                <div class="level p-0 m-0">
                    <div class="level-left">
                        <div class="level-item">Lista e departamenteve</div>           
                    </div>
                    <div class="level-right">
                        <div class="level">
                            <a @click.prevent="create_department">
                                <span class="mr-2">Krijo</span>
                                <i class="fa fa-plus"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <table class="table mt-4">
                    <thead>
                        <tr>
                            <th>Kodi</th>
                            <th>Emri</th>
                            <th>Printeri</th>
                            <th>Modifiko</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dept in departments" :key="dept.id">
                            <td>{{dept.code}}</td>
                            <td>{{dept.name}}</td>
                            <td>{{dept.printer_ip}}</td>
                            <td><a href="" @click.prevent="edit_department(dept)"><i class="fa fa-pencil"></i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="column is-5" v-if="form_edit || form_create">
                <DaForm v-if="form_create" @submitted="submitDepartment" :form="form_create">
                </DaForm>

                <DaForm v-if="form_edit" 
                @submitted="submitDepartment" 
                :form="form_edit" 
                >
                </DaForm>
            </div>
        </div>
    </div>
</template>

<script>
import DaForm from "@/components/daform/index.vue";
import createDepartment from "./forms/create";
import editDepartment from "./forms/edit";
import DepartmentsServices from "@/services/Department";
export default {
components: {
    DaForm
},
data() {
    return {
        departments: [],
        selectedDepartment: {},
        form_create: null,
        form_edit: null,
    }
},
methods: {
    create_department() {
        this.form_edit = null
        this.form_create = createDepartment.form(this.route_param)
    },
    edit_department(de) {
        this.form_create = null
        this.form_edit = null
        setTimeout(() => {
            let form = editDepartment.form(de, this.route_param)

            this.form_edit = form
            this.selectedDepartment = de
        })
    },
    async submitDepartment(d) {
        var notify_text = ""
        if(this.form_create != null) {
            let department = await DepartmentsServices.createDepartment(d, this.route_param)
            d.vendor_id = this.route_param
            var a = this.departments
            a.push(department)
            this.departments = [...a]
            notify_text = "Departamenti u krijua me sukses"
            this.form_create = null
            this.$root.selected_one = {}
        }
        else {
            d.guid = this.selectedDepartment.guid
            let department = await DepartmentsServices.editDepartment(d, this.route_param)
            this.departments = [...this.departments.map(a => {
                if(a.guid == department.guid) a = department
                return a
            })]
            notify_text = "Departamenti u modifikua me sukses"
            this.form_edit = null
            this.$root.selected_one = {}
        }
        this.$toast.success(notify_text)
    }
},

computed: {
    route_param() {
        return this.$route.params.vendor_id
    }
},
async created() {
    this.departments = await DepartmentsServices.getDepartments(this.route_param)
}
}
</script>

<style lang="scss" scoped>

</style>