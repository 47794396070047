export default {
    form: (department) => {
      return {
        name: "Modifiko Departamentin",
        submit: {
          label: "Modifiko",
          action: "submitDepartment",
        },
        fields: [
          [
            {
              value: department.code,
              name: "code",
              label: "Kodi",
              type: "text-field",
              style: "flex-grow: 0.3",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
              unique: {
                model: "department",
                field: "code",
                where: [
                    {
                        column: 'guid',
                        condition: '!=',
                        value: department.guid
                    }
                ],
              },
              left_icon: "fa-solid fa-hashtag",
            },
            {
              value: department.name,
              name: "name",
              label: "Emri",
              type: "text-field",
              style: "flex-grow: 0.6",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
            },
          ],
          {
            value: department.printer_ip,
            name: "printer_ip",
            label: "Printeri",
            type: "text-field",
            required: true,
            required_text: "Kjo fushe eshte e detyrueshme",
          }
        ],
      };
    },
  };
  
  